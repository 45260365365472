import { Box, Typography } from '@mui/material';
import * as React from 'react';
import Tag from '../../components/Tag';
import Footer from './footer';
import ScrollToTopIcon from '../../components/ScrollToTopIcon';

export default function Security(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <body>
      <div class="wrapper clearfix">
        <article class="onethird">
          <div class="spwrapper">
            <div class="companybox clearfix">
              <h3 class="consen">個人情報の取り扱いについて</h3>
                <Box className="company-privacy-policy">
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"はじめに"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>弊社は、貴殿の個人情報を適切かつ安全に管理し、外部からの個人情報への不正アクセスやその不正使用、<br />紛失、破壊、改ざんおよび漏洩等を予防する保護策を講じています。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"1. 事業者の名称"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}><Typography style={{ textAlign: 'left', padding: '10px 0' }}>コラボテクノ株式会社</Typography></Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"2. 個人情報保護管理責任者"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}><Typography style={{ textAlign: 'left', padding: '10px 0' }}>代表取締役　吉田　光哉</Typography></Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"3. 個人情報の利用目的"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>
                          ①開示対象個人情報の利用目的<br /> Ⅰ. 従業員の個人情報　　　　　（従業員管理に利用）<br /> Ⅱ. 営業関連個人情報　　　　（営業活動に利用）<br /> Ⅲ. 採用応募者の個人情報　　（採用選考に利用）<br />
                          ②開示対象個人情報以外の個人情報とその利用目的<br /> Ⅰ. 協力会社技術者の個人情報 （業務管理に利用）<br /> Ⅱ. 受託業務に関する個人情報 （システム開発・保守のために利用）
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"4. 個人情報の第三者への提供について"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}><Typography style={{ textAlign: 'left', padding: '10px 0' }}>法令で定める場合を除き、貴殿の個人情報を第三者へ提供することはありません。<br />尚、第三者への提供が必要な場合は、貴殿の同意を得た上で行います。</Typography></Box>
                    </Box>
                  </div>

                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"5. 個人情報の委託について"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>個人情報の取扱いの委託を行う場合は、十分な個人情報保護の水準を満たす者を選定し、<br />契約等によって保護水準を担保致した上で行います。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"6. 貴殿からの開示等の申込みへの応諾"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>貴殿は弊社に対してご自身の個人情報の利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去、第三者への提供の停止及び第三者提供記録の開示（”開示等”という）を下記に記す「個人情報保護　苦情・相談窓口」に申し出ることができます。その際、弊社はその処置について速やかに対応いたします。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"7. 個人情報提供の任意性"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>貴殿の個人情報の弊社へのご提供は任意です。ただし、必要な個人情報をご提供いただけなかった場合は、利用目的が達成できない可能性がありますことをご了承下さい。</Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"8. 保有個人データの安全管理のために講じた措置"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>
                          （本人の知り得る状態に置くことにより当該保有個人データの安全管理に支障を及ぼすおそれがあるものを除く。）<br />
                          個人情報を取り扱うにあたり、組織的、人的、物理的、技術的等の安全管理措置を講じております。<br />
                          ［組織的安全管理措置］<br />
                          ・取得、利用、保存、提供、廃棄等の局面ごとに、取り扱い方法、責任者・担当者及びその役割等について個人情報保護規程を策定しています。<br />
                          ［人的安全管理措置］<br />
                          ・従業者に対し、個人データの取り扱いについて、定期的に教育を行っています。<br />
                          ・従業者との間で、秘密保持にかかわる誓約書を取り交わしています。<br />
                          ［物理的安全管理措置］<br />
                          ・従業者及び来訪者の入退室管理を行うとともに、権限のない従業者による個人情報の取扱いを禁止する措置を実施しています。<br />
                          ［技術的安全管理措置］<br />
                          ・個人情報データベースへのアクセス制御を行い、個人データを取扱う従業者の範囲を制限しています。<br />
                          ・個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div class="company-contents-with-tag">
                    <Box>
                      <Tag tagText={"9. 保有個人データ又は第三者提供記録に関する開示等の手続き"} />
                    </Box>
                    <Box sx={{ margin: '10px 0px 0px -5px' }}>
                      <Box style={{
                          padding: '5px 10px 15px',
                          textAlign: 'left',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                      }}>
                        <Typography style={{ textAlign: 'left', padding: '10px 0' }}>
                          当社への開示等の手続きは下記の通り。<br />

                          Ⅰ. 問い合わせ先<br />
                          開示等の求めについては、まずは、お電話又はメールにて「個人情報保護　苦情・相談窓口」にご連絡ください。書類をご郵送いたします。<br />
                          お手元に届きました当社指定の様式「個人情報開示等請求書」に必要事項を記入し、郵送にて「個人情報保護　苦情・相談窓口」にご送付下さい。<br />
                          直接ご来社頂いてのご請求はお受けできませんのでご了承下さい。<br />

                          Ⅱ. 開示等の対象範囲<br />
                          当社が取得し、現に保有している保有個人データとします。<br />

                          Ⅲ. 提出書類<br />
                          手続き時にご提出頂く書類は以下の通りです。<br />
                          なお、ご提出頂きました書類は返却致しませんのでご了承下さい。<br />

                          【ご本人が開示等の請求を行う場合】<br />
                          当社指定の様式「個人情報開示等請求書」を当社から送付致しますので、その旨お申し付けください。<br />
                          その書類がお手元に届きましたら、必要事項をご記入の上、本人確認のための書類（Ⅳ.項を参照）を同封し、「個人情報保護　苦情・相談窓口」宛にご郵送ください。<br />

                          【代理人による開示等の請求を行う場合】<br />
                          当社指定の様式「個人情報開示等請求書」を当社から送付致しますので、その旨お申し付けください。<br />
                          その書類がお手元に届きましたら、代理人の署名、押印、住所および本人の氏名、その他必要事項をご記入のうえ、本人確認のための書類（Ⅳ項を参照）と代理人であることを証明する書類（下記参照）を同封し、「個人情報保護　苦情・相談窓口」宛にご郵送下さい。<br />

                          代理人であることを証明する書類<br />
                          （法定代理人の場合）<br />
                          法定代理権があることを証明できる書類　1通<br />
                          （委任による代理人の場合）<br />
                          本人の実印を押印して作成された委任状　1通<br />
                          本人の印鑑証明書（取得後３ヶ月以内）　1通<br />

                          Ⅳ. 本人確認事項<br />
                          本人確認書類として以下のうちの１点をコピーし、同封して下さい。いずれもご本人の氏名、住所の記載がある部分をコピーし、その他の記載は判読不能として下さい。<br />
                          a．運転免許証　　b．パスポート　　c．住民票の写し<br />

                          Ⅴ. 手数料<br />
                          開示等の手続きにおける当社への手数料はございません。但し、本人確認書類の取得費、代理人書類の取得費および申請書の送料はご本人負担となります。<br />

                          Ⅵ. 開示等のご請求にて取得した個人情報の利用について<br />
                          開示等のご請求に伴い、ご提出いただいた「個人情報開示等請求書」、本人確認書類、代理人確認書類等の個人情報は、開示等のご請求に関するご本人とのご連絡、またはご本人との間で疑義が生じた場合の確認等、開示等のご請求への対応に必要な範囲内でのみ取り扱います。<br />
                          ご提出いただいた書類は、開示等のご請求に対する回答が終了後、遅滞なく適切な方法で破棄させていただきます。<br />
                          <br />

                          個人情報に関する本人の権利における保有個人データの問合せに関する申し出先及び個人情報取扱に関する苦情及び相談についての申し出先<br />
                          【個人情報保護　苦情・相談窓口】<br />
                          個人情報苦情・相談窓口責任者　　代表取締役　吉田　光哉<br />
                          <br />
                          コラボテクノ株式会社<br />
                          〒108-0023　東京都港区芝浦3丁目17-11天翔田町ビル1002号<br />
                          Mail：yoshida@colla-tech.co.jp
                        </Typography>
                      </Box>
                    </Box>
                  </div>

                  <Typography style={{ textAlign: 'left', padding: '10px 0', fontWeight: 'bold' }}>認定個人情報保護団体について</Typography>
                  <Typography style={{ textAlign: 'left', padding: '10px 0', fontSize: '14px' }}>
                    当社は一般財団法人日本情報経済社会推進協会より、プライバシーマークの認定を受けております。<br />
                    個人情報の取扱いに関する苦情等の申し出先は以下の通りです。<br />
                    <br />
                    【認定個人情報保護団体の名称及び苦情の解決の申し出先】<br />
                    ※個人情報の取り扱いに関する苦情のみを受付けています。<br />
                    名称：一般財団法人日本情報経済社会推進協会<br />
                    　　　認定個人情報保護団体事務局<br />
                    住所：〒106-0032<br />
                    　　　東京都港区六本木1-9-9　六本木ファーストビル内<br />
                    電話番号：03-5860-7565、0120-700-779<br />
                  </Typography>
                </Box>
            </div>
          </div>
        </article>
      </div>
      <ScrollToTopIcon />
      <Footer />
    </body>
  )
}