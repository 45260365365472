import * as React from 'react';
import '../../../App.css';
import News from '../../../components/corporate/News';
import { Typography } from '@mui/material';

export default function News3(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <News
      title={'コラボテクノ株式会社新橋オフィス移転のお知らせ'}
      date={'2025/01/10(金)'}
      text={<Typography variant="body1" style={{ textAlign: 'left', padding: '10px 0' }}>
          コラボテクノ株式会社（代表取締役吉田光哉）は、令和7年2月10日（月）より田町にオフィスを移転いたします。<br />
          お近くにお越しの際は、ぜひお立ち寄りくださいませ。<br /><br/>
          住所<br />
          〒108-0023<br />
          東京都港区芝浦3丁目17-11天翔田町ビル1002号<br />
          アクセス：<a href="https://g.co/kgs/NyqY5bH" target="_blank" rel="noopener noreferrer">こちら</a>
      </Typography>}
    ></News>
  );
}