import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import Tag from "../components/Tag";
import AccordionRequirementsEntry from "../components/AccordionRequirementsEntry";
import ScrollToTopIcon from '../components/ScrollToTopIcon';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "5%",
            marginBottom: "0",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"Current Openings"} />
          <Grid container Spacing={1} >
            <Typography  sx={{
                fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" },
                textAlign: 'left',
                margin: '0',
                px:{xs:3,sm:1,md:1,xl:2},
                padding:{ xs:"5% 0 0 5%", sm:"5% 0 0 5%", md:"5% 0 0 1%", lg:"1% 0 0 0.5%" },
                color:`${colorTheme.palette.navy.main}`
              }}
              fontWeight={'bold'}
            >
              現在募集中の求人一覧
            </Typography>
          </Grid>
        </Box>

        <Box sx={{ paddingBottom: "10%" }}>

          <Typography id="mid-career" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
            中途採用
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["【募集背景】",
                "当社「コラボテクノ」では、現在、大手のSierに対し、",
                "WEB開発、クラウド基盤構築を中心にしたSES事業を中心に行っていますが、",
                "今後これに加え、企業のIT化支援（DX推進やITコンサルティング）を展開したいと考えおり、",
                "今後の事業の仲間になっていただける方を積極的に募集します。"
              ]}
              jobContent2={["【業務概要】",
                            "・PJでアーキテクチャ、設計、見積りを行いメンバーリードする",
                            "・品質管理",
                            "・既存・新規クライアントとコミュニケーションを図り深耕を行う",
                            "・エンジニアの育成、能力・特性に応じた若手エンジニアの教育"
                          ]}
              jobContent3={[
                            "【業務詳細】", 
                            "■顧客・新規クライアントの深耕",
                            "売上げ拡大のために、既存クライアントに入り込み深耕を行う",
                             "新規クライアントにアプローチし関係を構築する","　",
                            "■品質管理",
                            "メンバーが作成した開発成果物に対してレビューを⾏い、内容が妥当であるか確認する",
                            "※開発成果物として下記などを想定する",
                            "・UI ∕ UX含めた動作",
                            "・設計書",
                            "・コード",
                            " ・テスト仕様書、および、実⾏結果"
                            ,"妥当でない場合は対話などで状況を確認、対応が必要な場合は原因を追究し改善する","　",
                            "■進捗管理",
                            "・進捗の予実管理",
                            "　進捗の予実管理を⾏う",
                            "　予実に差異が発⽣した際はその原因を追求し改善する",
                            "・進捗の妥当性確認","　進捗が妥当であるか確認を⾏う",
                            "　妥当でない場合は対話などで状況を確認し、対応が必要な場合は原因を追究し改善する","　",
                            "■生産性向上",
                            "・プロセス改善",
                            "　業務を行う上でボトルネックとなっている箇所を把握し改善する",
                            "・開発環境の整備","　エンジニアが開発する上でボトルネックとなっている個所を把握し改善する","　",
                            "■エンジニアリング力向上",
                            "・育成",
                            "　⽬標設定や1on1を通じ、エンジニアの市場価値の向上を導く",
                            "・教育","　エンジニアの目標/スキルに応じて、教育・研修を推進する","　",
                            "■情報発信",
                            "　記事執筆や登壇などの情報発信を行い、コラボテクノのプレゼンスを向上する","　",
                            "【仕事の魅力と希少性】","・ Techサイドから大きな裁量をもってリードできる",
                            "・ 第二創業期の成長フェーズのため、開発をリードする中核メンバーに喰いこみやすいフェーズ"
                          ]}
              Menutext="プロジェクトマネージャー"
              Menudetail={[]}
              condition={[
                          "【必須スキル】",
                          "・なんらかのオープン言語での開発5年以上",
                          "・課題抽出を適切に行い、その解決策を正しく策定する力",
                          "・課題や対応内容を適切に言語化し、関係者に分かりやすく説明する力",
                          "・年間25人月程度のプロジェクトマネジメント経験",
                          "・エンジニア3人以上のヒューマンマネジメント経験",
                          "・Webサービス開発における、要件定義から運用保守までの一連の経験",
                          "・アンラーニング",
                          "（足りないスキルセットを自ら学習し補う力、自身の経験やスキルセットをそれに適応させる力）",
                          "・コラボテクノのMission/Vision/Valuesへの共感いただける方","　",
                          "【歓迎スキル】",
                          "・開発プロセスのロードマップ・生産性向上・エンジニアリング力向上などの領域をリードし",
                          "　ビジネスの価値向上/働きやすい環境の整備などを行ってきたご経験","　",                          
                          "【求める人物像】",
                          "・主体的にものごとを進められる方","・整備されていない環境を楽しめる方"
                        ]}
              recruitingCount="2-3名"
              workPlace="東京都港区芝浦3丁目17-11天翔田町ビル1002号(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　 7,200,000円　～　10,000,000円※ご経験により異なります。"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={[
                "◇雇用保険",
                "◇ 厚生年金",
                "◇ 労災保険", 
                "◇ 健康保険", 
                "◇ 交通費支給あり", 
                "◇ テレワーク・在宅OK", 
                "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", 
                "◇チーム懇親会費用負担", 
                "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", 
                "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
