import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import Tag from "../components/Tag";
import AccordionRequirementsEntry from "../components/AccordionRequirementsEntry";
import ScrollToTopIcon from '../components/ScrollToTopIcon';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "5%",
            marginBottom: "0",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"Current Openings"} />
          <Grid container Spacing={1} >
            <Typography  sx={{
                fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" },
                textAlign: 'left',
                margin: '0',
                px:{xs:3,sm:1,md:1,xl:2},
                padding:{ xs:"5% 0 0 5%", sm:"5% 0 0 5%", md:"5% 0 0 1%", lg:"1% 0 0 0.5%" },
                color:`${colorTheme.palette.navy.main}`
              }}
              fontWeight={'bold'}
            >
              現在募集中の求人一覧
            </Typography>
          </Grid>
        </Box>

        <Box sx={{ paddingBottom: "10%" }}>

          <Typography id="mid-career" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
            中途採用
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["【開発プロジェクトの実績】","■通信会社向け店頭顧客対応の開発", "■農業事業者向けクラウドサービス導入支援", "■荷物追跡サービス開発", "■アパレル会社向けEコマース保守開発","■AWSの要件定義、設計、開発","配属プロジェクトは入社時期によって異なります。","詳細は面接にてお問合せください。"]}
              jobContent2={[]}
              jobContent3={[]}
              Menutext="インフラエンジニア"
              Menudetail={[]}
              condition={["■何かしらのクラウド基盤・インフラ構築支援の経験2年以上", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方", "■素直に物事を捉えられる方"]}
              recruitingCount="2-3名"
              workPlace="東京都港区芝浦3丁目17-11天翔田町ビル1002号(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　 5,000,000円　～　8,000,000円"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
