import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import Tag from "../components/Tag";
import AccordionRequirementsNewGraduates from "../components/AccordionRequirementsNewGraduates";
import ScrollToTopIcon from '../components/ScrollToTopIcon';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "5%",
            marginBottom: "0",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"Current Openings"} />
          <Grid container Spacing={1} >
            <Typography  sx={{
                fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" },
                textAlign: 'left',
                margin: '0',
                px:{xs:3,sm:1,md:1,xl:2},
                padding:{ xs:"5% 0 0 5%", sm:"5% 0 0 5%", md:"5% 0 0 1%", lg:"1% 0 0 0.5%" },
                color:`${colorTheme.palette.navy.main}`
              }}
              fontWeight={'bold'}
            >
              現在募集中の求人一覧
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ paddingBottom: "10%" }}>

        <Typography id="new-graduates" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
          新卒採用
        </Typography>

        <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsNewGraduates
              category="新卒採用"
              jobContent="Webシステム開発"
              Menutext="システムエンジニア"
              Menudetail={[]}
              condition={["■素直に物事を捉えられる方", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方"]}
              recruitingCount="若干数"
              workPlace="東京都港区芝浦3丁目17-11天翔田町ビル1002号(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　3,240,000円　～　3,600,000円"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）",
                "◇入社に伴う引っ越しに関しての支援金 (１都３県以外からの引っ越しに限る)"]}
              url="https://forms.gle/XeWKThHMq2r43BBj6"
            ></AccordionRequirementsNewGraduates>
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
